export const characterStatuses = [
  {
    value: 'Dead',
    label: 'Dead',
  },
  {
    value: 'Alive',
    label: 'Alive',
  },
  {
    value: 'unknown',
    label: 'Unknown?',
  },
];

export const characterGenders = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'unknown',
    label: 'Unknown?',
  },
];
